/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Add smooth scrolling to all links
  $("a").on('click', function (event) {
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function () {

        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    } // End if
  });

  /*$(function(){
    'use strict';
    var options = {
      prefetch: true,
      cacheLength: 2,
      onStart: {
        duration: 250, // Duration of our animation
        render: function ($container) {
          // Add your CSS animation reversing class
          $container.addClass('is-exiting');

          // Restart your animation
          smoothState.restartCSSAnimations();
        }
      },
      onReady: {
        duration: 0,
        render: function ($container, $newContent) {
          // Remove your CSS animation reversing class
          $container.removeClass('is-exiting');

          // Inject the new content
          $container.html($newContent);

        }
      }
    },
    smoothState = $('#main').smoothState(options).data('smoothState');
  });*/

  var HomeSwiper = new Swiper('#banner-slides.swiper-container', {
    pagination: {
      el: '.home.swiper-pagination',
      clickable: true,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    loop: true,
    draggable: false,
  });


  var novidadesSwiper = new Swiper('#news-slides.swiper-container', {
    pagination: {
      el: '.news.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.news.swiper-button-next',
      prevEl: '.news.swiper-button-prev',
    },
    breakpoints: {
      // when window width is <= 1000px
      1000: {
        slidesPerView: 'auto',
        spaceBetween: 22,
      }
    },
    slidesPerView: 3,
    spaceBetween: 30,
  });


  var wishesSwiper = new Swiper('#wishes.swiper-container', {
    pagination: {
      el: '.wishes.swiper-pagination',
      clickable: true,
    },
    slidesPerView: 3,
    spaceBetween: 30,
    navigation: {
      nextEl: '.wishes.swiper-button-next',
      prevEl: '.wishes.swiper-button-prev',
    },
    breakpoints: {
      // when window width is <= 1000px
      1000: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      // when window width is <= 1600px
      1280: {
        slidesPerView: 2,
        spaceBetween: 30
      }
    },
  });

})(jQuery); // Fully reference jQuery after this point.
